import React from "react";
import HeaderBar from "components/docs/layout/HeaderBar";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import StaticFancyPageContainer from "components/docs/shared-components/StaticFancyPageContainer";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import styled from "styled-components";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import TwoColumnCenteredSection from "components/docs/sections/TwoColumnCenteredSection";
import { StaticImage } from "gatsby-plugin-image";
import { palette, spectrum } from "styles/theme";
import GetStartedLeftSidebar from "components/docs/get-started/GetStartedLeftSidebar";
import { Link } from "gatsby";
import LINKS from "components/docs/navigation/links";
import GetStartedOnboardingStep from "components/docs/get-started/GetStartedOnboardingStep";
import GlowButton from "components/generic/Button/GlowButton";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import {
  GettingStartedSidebarContentBox,
  GettingStartedStickyRightSidebar,
} from "components/docs/shared-components/StickyRightSidebar";
import { Globe } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import useCategories from "components/docs/hooks/useCategories";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { CategoryPrimaryModelInfo } from "types/types";
import AsideBarContent from "components/generic/AsideBarContent";

const StyledNavigationScrollTrackerWithAnchor = styled(NavigationScrollTrackerWithAnchor)`
  padding-left: 0px;

  @media (max-width: 991px) {
    padding-left: 24px;
  }
`;

const TwoColumnSection = styled(TwoColumnCenteredSection).attrs({ padding: 20 })`
  margin-top: 16px;
`;

const FreeAccountsBox = styled(GettingStartedSidebarContentBox)`
  background: ${spectrum.indigo0};
  margin-bottom: 24px;

  svg {
    color: ${palette.indigo};
  }

  .row + .row {
    margin-top: 14px;
  }
`;

const MobileOnlyContent = styled.div`
  margin-top: 16px;

  @media (min-width: 992px) {
    display: none;
  }
`;

const CategoryTitle = styled(Row)`
  color: ${palette.slate};
`;

const RightSidebarContent = () => {
  const categories = useCategories();

  return (
    <AsideBarContent
      Icon={<Globe />}
      content={
        <>
          <p>We recommend these for testing:</p>
          {(Object.keys(APICategory) as APICategory[]).map((category) => (
            <Row key={category} className="mx-0">
              <Col className="col-1 mr-6 d-flex flex-column justify-content-center">
                <CategoryTitle>{categories[category].abbreviation}</CategoryTitle>
              </Col>
              <Col className="px-0 d-flex flex-column justify-content-center">
                <a
                  href={CategoryPrimaryModelInfo[category].exampleProviderLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-flex align-content-middle"
                >
                  {CategoryPrimaryModelInfo[category].exampleProviderName}&nbsp;
                  <i className="fe fe-arrow-up-right" />
                </a>
              </Col>
            </Row>
          ))}
        </>
      }
      title={"Free Accounts"}
    />
  );
};

const LinkedAccountPage = () => {
  const categories = useCategories();
  return (
    <StaticFancyPageContainer
      leftSidebar={<GetStartedLeftSidebar />}
      rightSidebar={
        <GettingStartedStickyRightSidebar scrollHeightPercentage={90}>
          <RightSidebarContent />
        </GettingStartedStickyRightSidebar>
      }
    >
      <DocsHelmet
        title="Merge — Linked Account"
        description="Learn how to use Merge's features via our guided introductions."
      />

      <SmallWidthSection fadeBackground={false}>
        <NavigationScrollTracker>
          <HeaderBar
            title="Linked Account"
            subtitle={
              <p>
                <b>Linked Accounts</b> represent your end users’ connections between your app and
                third-party platforms.
              </p>
            }
            styling="top-level"
          />
        </NavigationScrollTracker>
      </SmallWidthSection>

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor
              title="Create a Test Linked Account"
              headingLevel="h3"
            />
            <p>
              Press <b>Create test Linked Account</b> in{" "}
              <a
                href="https://app.merge.dev/linked-accounts/test-accounts"
                target="_blank"
                rel="noreferrer"
              >
                Test Linked Accounts
              </a>
              .
            </p>
            <ol className="mt-n1">
              <li>Select an integration.</li>
              <li>Follow the steps to set up the integration.</li>
            </ol>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/linked-account/create-test-linked-account.png"
            alt="Create a Test Linked Account Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SmallWidthSection fadeBackground={false}>
        <MobileOnlyContent>
          <RightSidebarContent />
        </MobileOnlyContent>
      </SmallWidthSection>

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Syncing" headingLevel="h3" />
            <p>
              Your Linked Account will start syncing data from the third-party platform
              automatically.
            </p>
            <p>
              You can view sync status in your{" "}
              <a
                href="https://app.merge.dev/linked-accounts/accounts"
                target="_blank"
                rel="noreferrer"
              >
                Linked Accounts
              </a>
              .
            </p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/linked-account/syncing.png"
            alt="Syncing Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <TwoColumnSection
        columnA={
          <>
            <StyledNavigationScrollTrackerWithAnchor title="Pull Data" headingLevel="h3" />
            <p>
              Pull Linked Account data into your product by following the instructions in{" "}
              <Link to={LINKS.GET_STARTED_UNIFIED_API.linkTo}>
                {LINKS.GET_STARTED_UNIFIED_API.text}
              </Link>
              .
            </p>
            <p>
              To authenticate your API requests to Merge, save your end users&apos; Account Token in
              your database.
            </p>
            <p>
              For Test Linked Accounts, you can find the Account Token in the{" "}
              <a
                href="https://app.merge.dev/linked-accounts/test-accounts"
                target="_blank"
                rel="noreferrer"
              >
                Linked Accounts
              </a>{" "}
              Dashboard (see image to right).
            </p>
            <p>
              For Production Linked Accounts, save the Account Token that is returned from your{" "}
              <Link to={LINKS.GET_STARTED_LINK.linkTo}>{LINKS.GET_STARTED_LINK.text}</Link> after
              your end users authorize integrations.
            </p>
          </>
        }
        columnB={
          <StaticImage
            src="../../assets/images/docs/get-started/linked-account/pull-data.png"
            alt="Pull Data Image"
            quality={100}
            placeholder="none"
            layout="constrained"
            loading="eager"
          />
        }
      />

      <SectionDivider fadeBackground={false} />

      <SmallWidthSection fadeBackground={false}>
        <GetStartedOnboardingStep
          stepLabel="Next Up"
          stepTitle="In the next guide, learn how to install Link&hellip;"
          stepContent={
            <>
              <p className="mb-6">
                Enable your end users to create Linked Accounts from your app by following our{" "}
                <b>Link</b> guide.
              </p>
              <Link to={LINKS.GET_STARTED_LINK.linkTo}>
                <GlowButton
                  color={palette.black}
                  showChevron
                  borderRadius={6}
                  text="Go to Link guide"
                />
              </Link>
            </>
          }
        />
      </SmallWidthSection>
    </StaticFancyPageContainer>
  );
};

export default LinkedAccountPage;
